import { useEffect, useState } from 'react';

import Markdown from 'react-markdown';
import { Page } from '../Page/Page';
import welcomeMD from './welcome.md';

export const PageDemos = () => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(welcomeMD)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <Page>
      <Markdown>{markdown}</Markdown>
      <h1>UIAchievements</h1>
      <h2>Introduction</h2>
      <h2>Basic Achievements</h2>
      <p>
        Achievements can either be Common or Rare. The animation, sound, and
        labels differ between the two.
      </p>
      <button
        onClick={() =>
          new window.UIAchievement({
            title: 'A Simple Victory',
            score: 15,
            id: 'foo',
            onPop: () => {
              console.log('Function ran on mount');
            },
            onDestroy: () => {
              console.log('Function ran on unmount');
            },
            onClick: () => {
              const a = document.createElement('div');
              a.innerHTML = 'Function ran on click';
              console.log('Function ran on click');
              document.body.appendChild(a);
            },
          })
        }
      >
        Pop Common Achievement
      </button>{' '}
      <button
        onClick={() =>
          new window.UIAchievement({
            title: 'The Myth, The Legend!',
            score: 50,
            isRare: true,
            id: 'test-cheevo',
            onPop: (foo) => {
              console.log('Function ran on mount', foo);
            },
            onDestroy: () => {
              console.log('Function ran on unmount');
            },
          })
        }
      >
        Pop Rare Achievement
      </button>
    </Page>
  );
};
