import './MainLayout.css';

import { FileTextIcon, RocketIcon } from '../../icons';

import { PageDemos } from '../../pages/PageDemos/PageDemos';
import { PageDocs } from '../../pages/PageDocs/PageDocs';
import { useState } from 'react';

const PAGES = {
  demos: 'demos',
  documentation: 'documentation',
};
export const MainLayout = () => {
  const [activePage, setActivePage] = useState(PAGES.demos);

  return (
    <div className="layout">
      <Sidebar activePage={activePage} onClickPage={setActivePage} />
      <Content>
        {activePage === PAGES.demos && <PageDemos />}
        {activePage === PAGES.documentation && <PageDocs />}
      </Content>
    </div>
  );
};

const SidebarButton = ({ onClick, children, className, isActive, icon }) => {
  return (
    <button
      className={`sidebar-button ${className} ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <span className="button-icon">{icon}</span>

      <span className="button-label">{children}</span>
    </button>
  );
};

const Sidebar = ({ onClickPage, activePage }) => {
  return (
    <div className="sidebar">
      <SidebarButton
        onClick={() => onClickPage(PAGES.demos)}
        className={'green'}
        isActive={activePage === PAGES.demos}
        icon={<RocketIcon />}
      >
        Demos
      </SidebarButton>
      <SidebarButton
        onClick={() => onClickPage(PAGES.documentation)}
        className={'blue'}
        isActive={activePage === PAGES.documentation}
        icon={<FileTextIcon />}
      >
        Documentation
      </SidebarButton>
    </div>
  );
};

const Content = ({ children }) => {
  return <div className="content">{children}</div>;
};
