import './App.css';

import { MainLayout } from '../MainLayout/MainLayout';
import { useEffect } from 'react';

function App() {
  // useEffect(() => {
  //   const checkIsLoaded = document.querySelector("[data-achievements-loaded]");
  //   if (checkIsLoaded) return;

  //   const init = document.createElement("script");
  //   init.setAttribute(
  //     "src",
  //     "http://127.0.0.1:5500/plugin/achievements/index.js"
  //   );
  //   init.setAttribute("data-achievements-loaded", "true");

  //   document.body.appendChild(init);

  //   setTimeout(() => {

  //   }, 100);
  // }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://static.anthony.media/plugin/achievements/index.js';
    script.async = true;
    script.onload = () => {
      window.UIAchievementSystem.setOptions({
        // color: "red",
        // labelRare: "Diamond Award unlocked!",
        // labelCommon: "Prize unlocked!",
        enforceUnique: false,
        enableLogs: true,
        // customIcon: "http://127.0.0.1:5500/test-custom-logo2.png",
        // className: "adfaskdjfaksjdfklajskdlfjaslkdfjalskdjflkasdjf",
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="view">
      <MainLayout />
    </div>
  );
}

export default App;
