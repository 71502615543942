import Markdown from 'react-markdown';
import { Page } from '../Page/Page';
import docsMD from './docs.md';
import { useEffect } from 'react';
import { useState } from 'react';

export const PageDocs = () => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(docsMD)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);
  return (
    <Page>
      <Markdown>{markdown}</Markdown>
    </Page>
  );
};
